.container {
    .header {
        padding: var(--block-spacing);
        border-bottom: var(--menu-section-border);
    }

    .title {
        text-decoration: none;
    }

    .button {
        margin-top: var(--block-spacing);
        margin-bottom: var(--block-spacing);
        background: var(--map-button-transparency-color);
        transition: background 0.2s ease-out;

        &:hover {
            background: transparent;
        }
    }

    .icon {
        margin-left: 5px;
    }

    .list {
        padding-bottom: var(--block-spacing);
        border-bottom: var(--menu-section-border);

        &:last-child {
            border-bottom: none;
        }
    }

    .sectionTitle {
        font-size: 1rem;
    }

    .items {
        padding: 0 var(--side-spacing);
    }
}
