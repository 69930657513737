.container {
    display: flex;
    flex-direction: column;

    .button {
        margin: 0;

        &:first-child {
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
        }

        &:last-child {
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }
    }
}
