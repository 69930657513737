.container {
    padding-top: var(--block-spacing);

    .list {
        padding-bottom: var(--block-spacing);
        border-bottom: var(--menu-section-border);

        &:last-child {
            border-bottom: none;
        }
    }

    .sectionTitle {
        font-size: 1rem;
    }

    .items {
        padding: 0 var(--side-spacing);
    }
}
