.container {
    .header {
        padding: var(--block-spacing) var(--side-spacing);
        border-bottom: var(--menu-section-border);
    }

    .title {
        margin-bottom: var(--spacing);
    }

    .typeLabel {
        display: flex;

        .icon {
            width: 1.25em;
            margin-right: 0.75em;
        }
    }

    .address {
        margin-top: var(--block-spacing);
    }

    .list {
        padding-bottom: var(--block-spacing);
        border-bottom: var(--menu-section-border);

        &:last-child {
            border-bottom: none;
        }
    }

    .sectionTitle {
        font-size: 1rem;
    }

    .items {
        padding: 0 var(--side-spacing);
    }
}
