.container {
    position: relative;

    &.active {
        .pin {
            color: var(--map-pin-active-color);
        }
    }

    &:hover {
        .pin {
            color: var(--map-marker-hover-color);
        }
    }
}
