.container {
    position: relative;

    .circle {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border: var(--map-cluster-marker-border);
        border-radius: 50%;
        background: var(--map-button-color);
        box-shadow: var(--map-cluster-marker-box-shadow);
        color: var(--color-black);
        transition: background-color 0.2s ease-out;
    }

    .label {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-align: center;
        transform: translate(0, -50%);
    }

    &.active {
        .circle {
            background: var(--map-pin-active-color);
        }
    }

    &:hover {
        .circle {
            background: var(--map-marker-hover-color);
        }
    }
}
