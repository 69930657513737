.navigation {
    position: fixed;
    z-index: 2;
    top: var(--map-top-spacing);
    right: var(--map-side-spacing);
    pointer-events: all;
}

.select {
    position: fixed;
    z-index: 2;
    top: var(--map-top-spacing);
    right: calc(var(--map-side-spacing) + 2rem);
}
