.container {
    --side-spacing: var(--side-spacing-menu);
    --button-pill-color: var(--map-button-transparency-color);
    --button-pill-text-color: var(--color-black);

    position: relative;
    background-color: var(--map-sidebar-background-color);
    box-shadow: var(--map-sidebar-box-shadow-mobile);
    transition: transform 0.2s ease-out;

    .toggle {
        position: absolute;
        top: -1.5rem;
        left: 50%;
        width: 50px;
        height: 1.5rem;
        border-bottom: none;
        background-color: var(--map-sidebar-background-color);
        border-top-left-radius: var(--map-button-border-radius);
        border-top-right-radius: var(--map-button-border-radius);
        transform: translate(-50%, 0);
    }

    .caret {
        transform: rotate(0);
    }

    &.visible {
        .caret {
            transform: rotate(180deg);
        }
    }

    .inner {
        min-height: var(--map-sidebar-min-height);
    }

    .panel {
        padding-top: var(--block-spacing);
    }

    .documentPanel {
        position: relative;
        padding-top: 0;
    }

    .innerPanel {
        position: relative;
        z-index: 1;
        padding-top: var(--block-spacing);

        &.document {
            padding-top: 0;
        }
    }

    .backButton {
        position: absolute;
        z-index: 2;
        top: var(--block-spacing);
        left: var(--side-spacing);
    }

    .leave {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    @media (--small-viewport) {
        width: var(--map-sidebar-width);
        box-shadow: var(--map-sidebar-box-shadow);

        .toggle {
            top: 50%;
            right: -1.5rem;
            left: auto;
            width: 1.5rem;
            height: 50px;
            border-left: none;
            border-bottom-right-radius: var(--map-button-border-radius);
            border-top-left-radius: 0;
            border-top-right-radius: var(--map-button-border-radius);
            box-shadow: var(--map-sidebar-box-shadow);
            transform: translate(0, -50%);
        }

        .caret {
            transform: rotate(90deg);
        }

        &.visible {
            .caret {
                transform: rotate(-90deg);
            }
        }
    }
}
