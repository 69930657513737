.container {
    .header {
        padding: var(--block-spacing) var(--side-spacing);
        border-bottom: var(--menu-section-border);
    }

    .title {
        display: flex;
        align-items: center;
    }

    .icon {
        height: 2em;
        margin-right: 1em;
    }

    .list {
        padding-bottom: var(--block-spacing);
        border-bottom: var(--menu-section-border);

        &:last-child {
            border-bottom: none;
        }
    }

    .items {
        padding: 0 var(--side-spacing);
    }
}
