.container {
    .title {
        margin: 0;
    }

    .button {
        margin-top: var(--spacing);
    }

    .icon {
        margin-left: 5px;
    }
}
