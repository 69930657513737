.container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: var(--color-white);

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .sidebarContainer {
        position: relative;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 100vh;
        transition: margin-top 0.2s ease-out;
    }

    .sidebar {
        width: 100%;
        height: 100%;
    }

    .mapContainer {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: calc(100vh);
        transition: height 0.2s ease-out;
    }

    .marker {
        width: 30px;
        transition: width 0.2s ease-out;

        &.bigger {
            width: 30px;
        }
    }

    .regionsList {
        display: flex;
        flex-direction: column;
    }

    .regionButton {
        &.active {
            background-color: var(--map-active-color);
        }
    }

    @media (--medium-viewport) {
        .sidebarContainer {
            position: absolute;
            top: 0;
            width: var(--map-sidebar-width);
            height: 100%;
            margin-top: 0;
            transform: translateX(-100%);
            transition: transform 0.2s ease-out;
        }

        .sidebar {
            max-height: 100vh;
        }

        .mapContainer {
            width: 100%;
            height: 100%;
            transition: width 0.2s ease-out;
        }
    }

    &.sidebarOpened {
        position: relative;
        height: auto;

        .inner {
            position: relative;
            height: auto;

            /* overflow: auto; */
        }

        .sidebarContainer {
            margin-top: calc(100vh - 200px);
        }

        .mapContainer {
            height: calc(100vh - 200px);
        }

        @media (--medium-viewport) {
            position: absolute;
            overflow: hidden;
            height: 100%;

            .inner {
                position: absolute;
                overflow: hidden;
                height: 100%;
            }

            .sidebarContainer {
                margin-top: 0;
                transform: translateX(0);
            }

            .mapContainer {
                width: calc(100% - var(--map-sidebar-width));
                height: 100%;
            }

            .sidebarInner {
                position: absolute;
                top: 0;
                left: 0;
                overflow: auto;
                width: 100%;
                height: 100%;
            }
        }
    }
}
