.container {
    height: 2em;
    padding: 0 0.5em;
    background-color: var(--map-button-color);
    color: var(--coor-black);

    &:hover {
        background-color: var(--map-hover-color);
    }
}

.icon {
    display: block;
    width: 1em;
    height: 1em;
}
